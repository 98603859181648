import React from 'react';
import { useTranslation } from 'react-i18next';

export const ErrorPage = ({ message }) => {
	const { t } = useTranslation();

	return (
		<div className="error-page">
			<h1>{ message || t('error-page.message') }</h1>
		</div>
	);
};
