import React from 'react';
import { Button, Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const ActionsPopup = ({ id, isOpen, title, text, onClose, actions }) => {
	const { t } = useTranslation();
	return (
		<Dialog id={ id + '-popup' } open={ isOpen } onClose={ onClose }>
			<div className="accept-popup">
				{ title && <Typography variant="h5">{ title }</Typography> }
				{ text && <Typography className="mt-16 mb-32">{text}</Typography> }
				<div className="action-popup-buttons">
					{actions.map(action => (
						<Button key={action.id} id={'action-' + action.id} fullWidth variant="contained" size="large" onClick={action.func} color={action.isDanger ? 'error' : 'success'}>{t(action.name)}</Button>
					))}
					<Button id={id + '-cancel'} fullWidth variant="contained" size="large" onClick={onClose}>{t('cancel')}</Button>
				</div>
			</div>
		</Dialog>
	);
};