import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStores } from 'common-frontend/components/store-provider';
import { Button, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useBreadcrumbs } from '../components/user/breadcrumbs';
import { PATHS } from '../../constants/paths';


export const UserPage = observer(() => {
	const { UserStore } = useStores();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const { setBreadcrumbs } = useBreadcrumbs();
	const { user } = UserStore;

	useEffect(() => {
		setBreadcrumbs([
			{ label: 'studies' },
			{ label: 'user' },
		]);
	}, []);

	const handleBackClick = () => {
		if (location.key === 'default') {
			navigate(PATHS.studies);
		} else {
			navigate(-1);
		}
	};

	return (
		<>
			<div className="user-page">
				{/* <section>
					<div className="title">{t("user.section-account.title")}</div>
				</section>
				<section>
					<div className="title">{t("user.section-details.title")}</div>
				</section> */}
				<section className={user?.street ? 'active' : ''}>
					<div className="title">{t('user.section-addresses.title')}</div>
					<div className="content">
						<div className="address-row">
							<Typography>{t('user.section-addresses.street')}:</Typography>
							<input type="text" placeholder={t('user.section-addresses.street')} value={user?.street ? `${user.street} ${user.house}` : ''} readOnly={ true }/>
						</div>
						<div className="address-row">
							<Typography>{t('user.section-addresses.zip')}:</Typography>
							<input type="text" placeholder={t('user.section-addresses.zip')} value={user?.zip ? user.zip : ''} readOnly={ true }/>
						</div>
						<div className="address-row">
							<Typography>{t('user.section-addresses.city')}:</Typography>
							<input type="text" placeholder={t('user.section-addresses.city')} value={user?.city ? user.city : ''} readOnly={ true }/>
						</div>
					</div>
				</section>
				{/* <section>
					<div className="title">{t("user.section-additional.title")}</div>
				</section>
				<section>
					<div className="title">{t("user.section-policy.title")}</div>
				</section> */}
				<Button id="back-button" variant="contained" size="large" onClick={handleBackClick}>{t('back')}</Button>
			</div>
		</>
	);
});
