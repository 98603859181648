import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

class WalletStoreModel {
	storageKey = 'wallet-education';
	scores = {}; // { nursing: 25, math: 50 }

	constructor() {
		makeAutoObservable(this);
		makePersistable(this, { name: this.storageKey, properties: [ 'scores' ], storage: localStorage });
	}

	get coursesInProgress() {
		return Object.keys(this.scores)?.length ? Object.values(this.scores).some(score => score > 0) : false;
	}

	setScore = (course, value) => {
		this.scores = { ...this.scores, [course]: value };
	};

	getScore = (course) => {
		return this.scores[course];
	};

	cleanWalletData = () => {
		this.scores = {};
	};
	reset = () => {
		this.scores = {};
	};
}

class PopupStoreModel {
	isOpen = false;
	constructor() {
		makeAutoObservable(this);
	}
	togglePopup = () => {
		this.isOpen = !this.isOpen;
	};
	closePopup = () => {
		this.isOpen = false;
	};
	openPopup = () => {
		this.isOpen = true;
	};
}

class UserStoreModel {
	user = null;
	userStatusCode = null;

	constructor() {
		makeAutoObservable(this);
	}
	setUser = (value) => {
		this.user = value;
	};
	getUser = () => {
		return this.user;
	};

	setUserStatusCode = (value) => {
		this.userStatusCode = value;
	};
	getUserStatusCode = () => {
		return this.userStatusCode;
	};
	reset = () => {
		this.user = null;
		this.userStatusCode = null;
	};
}

class AuthStoreModel {
	authProfile = null;
	constructor() {
		makeAutoObservable(this);
	}
	setAuthProfile = (value) => {
		this.authProfile = value;
	};
	reset = () => {
		this.authProfile = null;
	};
}

class QrStoreModel {
	qrIsOpen = false;
	qrUrl = null;
	qrValidity = null;
	constructor() {
		makeAutoObservable(this);
	}
	toggleQr = () => {
		this.qrIsOpen = !this.qrIsOpen;
	};
	closeQr = () => {
		this.qrIsOpen = false;
	};
	openQr = () => {
		this.qrIsOpen = true;
	};
	setQrUrl = (value) => {
		this.qrUrl = value;
	};
	setQrValidity = (value) => {
		this.qrValidity = value;
	};
	reset = () => {
		this.qrIsOpen = false;
		this.qrUrl = null;
		this.qrValidity = null;
	};
}

class GuidesStoreModel {
	allRightMessageType = ''; // 'onboarded' | 'get-progress'
	constructor() {
		makeAutoObservable(this);
	}
	setAllRightMessageType = (value) => {
		this.allRightMessageType = value;
	};
	reset = () => {
		this.allRightMessageType = '';
	};
}

export const STORES = {
	PopupStore: new PopupStoreModel(),
	WalletStore: new WalletStoreModel(),
	UserStore: new UserStoreModel(),
	AuthStore: new AuthStoreModel(),
	QrStore: new QrStoreModel(),
	GuidesStore: new GuidesStoreModel(),
};

export const resetStores = () => {
	Object.values(STORES).forEach(store => store?.reset());
};
