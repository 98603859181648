import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { MainLayout } from './main/layout';
import { HomePage } from '../pages/home';
import { StudiesPage } from '../pages/studies';
import { ErrorPage } from 'common-frontend/components/error';
import { UserLayout } from './user/layout';
import { UserPage } from '../pages/user';
import { ProgressPage } from '../pages/progress';
import { PATHS } from '../../constants/paths';

export const Router = () => {
	return (
		<Routes>
			<Route element={ <MainLayout/> }>
				<Route path={ PATHS.home } element={ <HomePage/> } errorElement={ <ErrorPage/> }/>
				<Route path={ PATHS.studies } element={ <StudiesPage/> } errorElement={ <ErrorPage/> }/>
			</Route>
			<Route element={ <UserLayout/> }>
				<Route path={ PATHS.user } element={ <UserPage/> } errorElement={ <ErrorPage/> }/>
				<Route path={ PATHS.course } element={ <ProgressPage/> } errorElement={ <ErrorPage/> }/>
			</Route>
			<Route path="*" element={ <ErrorPage message={'404 page not found'}/> } />
		</Routes>
	);
};