import React from 'react';
import { Grid, Typography } from '@mui/material';
import { IMAGES } from '../../constants/images';
import { useTranslation } from 'react-i18next';

export const HomePage = () => {
	const { t } = useTranslation();

	return (
		<>
			<div className="header-image-container">
				<img className="header-image" src={ IMAGES.header } alt="header" />
			</div>
			<main className="main">
				<Grid container spacing={ 4 }>
					<Grid item lg={ 12 } md={ 12 }>
						<Typography variant="h1" className="mt-48 mb-16">{t('home.main.title')}</Typography>
						<Typography className="mb-16">{t('home.main.description')}</Typography>
					</Grid>
					<Grid item lg={ 12 } md={ 12 }>
						<div className="banner">
							<Typography variant="h4">{t('home.banner.title')}</Typography>
							<Typography>{t('home.banner.description')}</Typography>
						</div>
					</Grid>
				</Grid>
			</main>
		</>
	);
};
