import logo from '../assets/images/logo.png';
import logoSimple from '../assets/images/logo-simple.png';
import header from '../assets/images/header.jpg';
import footer from '../assets/images/footer-logo.png';
import girl from '../assets/images/girl.jpg';
import congrat from '../assets/images/congrat.png';
import favicon from '../assets/images/edu-fav.png';
import keyboard from '../assets/images/keyboard.jpg';

export const IMAGES = {
	logo,
	logoSimple,
	header,
	footer,
	girl,
	congrat,
	favicon,
	keyboard,
};
