import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Dialog } from '@mui/material';

export const onCleanNodes = (ids) => {
	let node = null;
	ids.forEach(id => {
		node = document.getElementById(id);
		if (node) {
			node.classList.remove('pulse', 'tooltip');
			node.removeAttribute('data-tooltip-value');
		}
	});
};

export const onCleanNodesClass = (className) => {
	const nodes = document.getElementsByClassName(className);

	if (nodes) {
		for (const node of nodes) {
			node.classList.remove('pulse', 'tooltip');
			node.removeAttribute('data-tooltip-value');
		}
	}
};

export const onPulse = (id) => {
	const node = document.getElementById(id);
	if (node) {
		node.classList.add('pulse');
	}
};

export const onPulseClass = (className) => {
	const nodes = document.getElementsByClassName(className);

	for (let node of nodes) {
		node.classList.add('pulse');
	}
};

export const onTooltip = (id, message, mainPosition = 'bottom', subPosition = 'center', isHoverOnly = false) => {
	/**
	 * mainPosition: top, bottom, left, right
	 * subPosition: start, center, end
	 * isHoverOnly - shows tooltip only then :hover()
	*/
	const node = document.getElementById(id);
	if (node) {
		node.classList.add('tooltip', `tooltip-${mainPosition}-${subPosition}`, isHoverOnly && 'tooltip-hover-only');
		node.setAttribute('data-tooltip', message);
		!isHoverOnly && node.classList.remove('tooltip-hover-only');
	}
};

export const nodeIsDisabled = (id, isDisabled) => {
	const node = document.getElementById(id);
	if (node) {
		isDisabled ? node.classList.add('disabled') : node.classList.remove('disabled');
	}
};

	export const AllRightMessagePopup = ({ messageType, isOpen, handler }) => {
	const { t } = useTranslation();
	return (
		<Dialog id={ `${ messageType }-all-right-popup` } open={ isOpen }>
			{ messageType &&
				<div className="all-right-popup">
					<p><Trans i18nKey={ `tooltips.${ messageType }-all-right` } /></p>
					<Button id="all-right-button" className="pulse" variant="contained" color="primary" onClick={ handler }>
						{ t('tooltips.all-right') }
					</Button>
				</div> }
		</Dialog>
	);
};