import React from 'react';
import { CircularProgress } from '@mui/material';
import { observer } from 'mobx-react';
import { useStores } from './store-provider';
import { useTranslation } from 'react-i18next';

export const Loader = observer(() => {
	const { t } = useTranslation();
	const { PopupStore } = useStores();
	const { isOpen } = PopupStore;

	if (!isOpen) return null;

	return (
		<div className="popup">
			<CircularProgress size={ 64 }/>
			<span className="popup__message mt-16">{ t('loader') }</span>
		</div>
	);
});
