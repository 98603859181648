import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { versionsFormater } from 'common-frontend/utils/formatters';
import { RestartScenarioButton } from '../restart-scenario-button';
import { ResetScoreButton } from '../reset-score-button';
import { SHOW_RESET_LEARNING_PROGRESS } from 'common-frontend/constants/env';

export const Footer = () => {
	const { t } = useTranslation();

	return (
		<footer className="simple" >
			<Typography>{t('footer-user.title')}</Typography>
			<RestartScenarioButton />
			{SHOW_RESET_LEARNING_PROGRESS && <ResetScoreButton />}
			<div className="versions-container">
				<VersionsMemo />
			</div>
		</footer>
	);
};

const VersionsMemo = React.memo(() => <Typography variant="caption">{ versionsFormater() }</Typography>);