import { MOCK_NAME } from '../constants/env';
import { getAsync, postAsync, deleteAsync } from '../utils/fetch-helper';
import { STORES } from './shared-stores';

export const GET_USER = async () => {
	const {
		UserStore: { setUser, setUserStatusCode, getUserStatusCode, setUserJustBoarded },
		QrStore: { closeQr }
	} = STORES;
	const previousUserStatusCode = getUserStatusCode();
	const urlPart = MOCK_NAME === 'education' || MOCK_NAME === 'chamber' ? MOCK_NAME : 'user';
	const response = await getAsync(`/${urlPart}`, [ 403, 404 ]);

	setUserStatusCode(response.status.toString());

	if (response.status === 200) {
		const data = await response.json();
		setUser(data);
		closeQr();

		if (setUserJustBoarded && previousUserStatusCode && previousUserStatusCode !== '200') {
			setUserJustBoarded(true);
		}

		return await SYNC_LANGUAGE();
	}

	setUser(null);
};

export const GET_QR = async () => {
	const {
		UserStore: { setUserStatusCode },
		QrStore: { setQrUrl, setQrValidity, closeQr },
	} = STORES;
	const response = await getAsync('/qrcode');

	if (response.status === 200) {
		const data = await response.json();
		setQrUrl(`nmshd://tr#${data.truncatedReference}`);
		setQrValidity(data.expiresAt);
		setUserStatusCode('403'); // predict, relationship created on BE side, next getUser() will return 403
		return;
	}

	closeQr();
};

export const REMOVE_RELATIONSHIP = async () => {
	const {
		UserStore: { setUser, setUserStatusCode },
	} = STORES;
	const response = await deleteAsync('/delete-relationship');

	if (response.status === 200) {
		setUser(null);
		setUserStatusCode('404');
	}
};

export const UPLOAD_DOCUMENT = async (uploadingFile) => {
	const formData = new FormData();
	formData.append('file', uploadingFile);
	await postAsync('/upload-file', formData);
};

export const DOWNLOAD_DOCUMENT = async (document) => {
	const response = await getAsync(`/documents/download/${document.uuid}`);

	if (response.status === 200) {
		const blob = await response.blob();
		const url = window.URL.createObjectURL(blob, { type: document.mimeType });
		const link = window.document.createElement('a');

		link.href = url;
		link.download = document.title ? `${document.title}.pdf` : document.filename;
		link.click();
		link.remove();
		window.URL.revokeObjectURL(url);
	}
};

export const SYNC_LANGUAGE = async () => {
	await getAsync('/i18n');
};