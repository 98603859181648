import React, { useCallback } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Typography } from '@mui/material';
import { useStores } from 'common-frontend/components/store-provider';
import { IMAGES } from '../../../constants/images';
import { SYNC_LANGUAGE } from 'common-frontend/services/api-calls';
import { useTranslation } from 'react-i18next';
import { PATHS } from '../../../constants/paths';


export const Header = observer(() => {
	const { t, i18n } = useTranslation();
	const { pathname } = useLocation();
	const { WalletStore, UserStore } = useStores();
	const { course } = useParams();
	const { getScore } = WalletStore;
	const { user } = UserStore;
	const score = getScore(course);

	const switchLanguage = useCallback((lang) => {
		i18n.changeLanguage(lang); // en || de
		user && SYNC_LANGUAGE();
	}, [ user, i18n, pathname ]);

	return (
		<>
			<MemoHeaderLogo />
			<div className="nav-container nav-container--simple mb-24">
				<nav className="nav nav--simple">
					<Typography id="score-value">
						{t('nav-user.score-value')}
						{course && <> - {t(`courses.${course}.name`)}</>}
						{score
							? <span translate="no">: {score}%</span>
							: ''}
					</Typography>
					<div className="language-switch-container">
						<a className="language-switch" translate="no" onClick={() => { switchLanguage('de'); }}>DE</a>
						<a className="language-switch" translate="no" onClick={() => { switchLanguage('en'); }}>EN</a>
					</div>
				</nav>
			</div>
		</>
	);
});

const MemoHeaderLogo = React.memo(() => (
	<div className="logo-wrapper logo-wrapper--simple">
		<div className="logo-container logo-container--simple">
			<Link id="home-logo-link" to={ PATHS.home }>
				<img className="logo logo--simple" src={ IMAGES.logoSimple } alt="logo"/>
			</Link>
		</div>
	</div>
));
