import React, { useCallback, useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useStores } from 'common-frontend/components/store-provider';
import { GET_SCORE, SEND_PDF, UPDATE_SCORE } from '../../services/api-calls';
import { IMAGES } from '../../constants/images';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { PATHS } from '../../constants/paths';
import { useBreadcrumbs } from '../components/user/breadcrumbs';


export const ProgressPage = observer(() => {
	const { t } = useTranslation();
	const { WalletStore, AuthStore, GuidesStore } = useStores();
	const { course } = useParams();
	const { pathname } = useLocation();
	const { setBreadcrumbs } = useBreadcrumbs();
	const { getScore } = WalletStore;
	const { authProfile } = AuthStore;
	const { setAllRightMessageType } = GuidesStore;

	const score = getScore(course);

	const [ localLoader, setLocalLoader ] = useState(false);

	const fetchScore = useCallback(() => {
		setLocalLoader(true);
		setAllRightMessageType('get-progress');

		GET_SCORE(course).finally(() => {
			setLocalLoader(false);
		});
	}, []);

	const updateScore = useCallback(() => {
		setLocalLoader(true);

		UPDATE_SCORE(course).finally(() => {
			setLocalLoader(false);
		});
	}, []);

	const sendPdf = useCallback(() => {
		setLocalLoader(true);

		SEND_PDF(course).finally(() => {
			setLocalLoader(false);
		});
	}, []);

	useEffect(() => {
		setBreadcrumbs([
			{ label: 'studies' },
			{ label: 'studies' },
			{ label: course, path: pathname }
		]);
	}, []);

	if (!authProfile || !sessionStorage.getItem('access_token-education')) {
		return (
			<main className="progress-main">
				<Typography variant="h2" className="mb-16">{t('progress.unauthorized.message')}</Typography>
				<Link to={ PATHS.user }>{t('progress.unauthorized.back')}</Link>
			</main>
		);
	}

	return (
		<main className="progress-main">
			{ score == null &&
				<div className="score-block">
					<Typography variant="h1">{t('progress.score-start.title')}</Typography>
					<Typography className="mt-16 mb-16">
						<Trans i18nKey="progress.score-start.text" values={{ userName: authProfile?.preferred_username }} />
					</Typography>
					<Button id="get-score-button" disabled={localLoader} variant="contained" size="large" onClick={ fetchScore }>{t('progress.score-start.next-button')}</Button>
				</div>
			}
			{ score != null && score < 100 &&
				<div className="score-block">
					<Typography variant="h1">{t('progress.score-progress.title')}</Typography>
					<Typography className="mt-16 mb-16">
						<Trans i18nKey="progress.score-progress.text" values={{ userName: authProfile?.preferred_username }} />
					</Typography>
					<Button id="update-score-button" disabled={localLoader} variant="contained" size="large" onClick={ updateScore }>{t('progress.score-progress.next-button')}</Button>
				</div>
			}
			{ score === 100 &&
				<div className="score-block">
					<Typography variant="h1">{t('progress.score-success.title')}</Typography>
					<Typography className="mt-16 mb-16">
						<Trans i18nKey="progress.score-success.text" values={{ userName: authProfile?.preferred_username, course: t(`courses.${course}.name`) }} />
					</Typography>
					<div className="buttons-container">
						<Button id="send-pdf-button" disabled={localLoader} variant="contained" size="large" onClick={ sendPdf }>{t('progress.score-success.next-button')}</Button>
						<Link to={ PATHS.studies }>
							<Button id="back-button" variant="contained" size="large">{t('progress.score-success.back-button')}</Button>
						</Link>
					</div>
					<img src={ IMAGES.congrat } alt="img" />
				</div>
			}
		</main>
	);
});
