import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './header';
import { Footer } from './footer';
import { BreadcrumbsProvider } from './breadcrumbs';

export const UserLayout = () => {
	return (
		<>
			<div className="page-wrapper">
				<Header />
				<BreadcrumbsProvider>
					<Outlet />
				</BreadcrumbsProvider>
			</div>
			<Footer />
		</>
	);
};
