import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStores } from 'common-frontend/components/store-provider';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { PATHS } from '../../constants/paths';
import { signIn } from 'common-frontend/utils/auth-handlers';
import {
	AllRightMessagePopup,
	onCleanNodes,
	onCleanNodesClass,
	onPulse,
	onPulseClass, onTooltip
} from 'common-frontend/components/guides';

export const Guides = observer(() => {
	const { pathname } = useLocation();
	const { t, i18n } = useTranslation();
	const { AuthStore, QrStore, UserStore, GuidesStore, WalletStore } = useStores();
	const { authProfile } = AuthStore;
	const { qrIsOpen } = QrStore;
	const { user, userStatusCode } = UserStore;
	const { allRightMessageType, setAllRightMessageType } = GuidesStore;
	const { getScore } = WalletStore;
	const course = pathname.split('/')[2];
	const score = course && getScore(course);

	const debounceRef = useRef(0);
	const currStepRef = useRef('');

	const [ allRightPopupIsOpen, setAllRightPopupIsOpen ] = useState(false);

	const validationRules =  useMemo(() => ({
		homePageUnauthorized: pathname === PATHS.home && !authProfile && !sessionStorage.getItem('access_token-education') && !allRightMessageType,
		studiesPageUnauthorized: pathname === PATHS.studies && !authProfile && !sessionStorage.getItem('access_token-education'),
		userPageUnauthorized: pathname === PATHS.user && !sessionStorage.getItem('access_token-education') && !authProfile && !allRightMessageType,
		userAuthorizedNotEnrolled: authProfile && !qrIsOpen && !user && userStatusCode !== '403',
		qrOpened: authProfile && qrIsOpen && !user,
		userNotBoarded: authProfile && !qrIsOpen && !user && userStatusCode === '403',
		userBoarded: authProfile && user && allRightMessageType === 'onboarded',
		boardedMessageClosed: pathname === PATHS.user && authProfile && user && !allRightMessageType,
		studiesPage: pathname === PATHS.studies,
		userOpenedCourse: pathname.includes('progress') && authProfile && !allRightMessageType && score == null,
		userGotScore: pathname.includes('progress') && authProfile && allRightMessageType === 'get-progress',
		scoreMessageClosed: pathname.includes('progress') && authProfile && score === 0,
		userFinishedCourse: pathname.includes('progress') && authProfile && score === 100,
	}), [ pathname, authProfile, qrIsOpen, user, allRightMessageType, score, userStatusCode ]);

	const stepRunners = useMemo(() => (
		{
			homePageUnauthorized() {
				onPulse('studies-link');
			},
			studiesPageUnauthorized() {
				onPulse('auth-link');
				onPulseClass('course-link--highlight');
			},
			userPageUnauthorized() {
				onCleanNodes([ 'studies-link' ]);
				onPulse('auth-link');
			},
			userAuthorizedNotEnrolled() {
				onCleanNodes([ 'auth-link' ]);
				onCleanNodesClass('course-link');
				onPulse('qr-button');
				onTooltip('qr-button', t('tooltips.click-for-qr'));
			},
			qrOpened() {
				onCleanNodes([ 'qr-button' ]);
				onTooltip('qr-tooltip-anchor' ,t('tooltips.scan-qr'));
				setAllRightMessageType('onboarded');
			},
			userNotBoarded() {
				onCleanNodes([ 'auth-link', 'qr-button' ]);
				setAllRightMessageType('onboarded');
			},
			userBoarded() {
				onCleanNodes([ 'auth-link' ]);
				setAllRightPopupIsOpen(true);
			},
			boardedMessageClosed() {
				onCleanNodes([ 'auth-link' ]);
				onPulseClass('course-link');
			},
			studiesPage() {
				onPulseClass('course-link');
			},
			userOpenedCourse() {
				onPulse('get-score-button');
			},
			userGotScore() {
				onCleanNodes([ 'get-score-button' ]);
				setAllRightPopupIsOpen(true);
			},
			scoreMessageClosed() {
				onPulse('update-score-button');
			},
			userFinishedCourse() {
				onCleanNodes([ 'update-score-button' ]);
				onPulse('send-pdf-button');
			}
		}
	), [ setAllRightMessageType, t ]);

	const updateStep = (forceUpdate = false) => {
		const stepValidator = () => {
			for (let key in validationRules) {
				if (validationRules[key]) {
					return key;
				}
			}
		};

		const setNewStep = () => {
			const newStep = stepValidator();

			if (newStep && (currStepRef.current !== newStep || forceUpdate)) {
				const allSteps = Object.keys(stepRunners);
				const newStepIndex = allSteps.indexOf(newStep);
				const maxDoneStepIndex = allSteps.indexOf(sessionStorage.getItem('max_done_guide_step'));

				if (maxDoneStepIndex < newStepIndex) {
					sessionStorage.setItem('max_done_guide_step', newStep.toString());
				}

				currStepRef.current = newStep;
				console.info('Run step', currStepRef.current);
				stepRunners[currStepRef.current]();
			}
		};

		return setNewStep();
	};

	useEffect(() => {
		const handleUpdate = () => {
			clearTimeout(debounceRef.current);
			debounceRef.current = setTimeout(updateStep, 300);
		};
		handleUpdate();
	}, [ authProfile, qrIsOpen, user, allRightMessageType, pathname, score, userStatusCode ]);

	useEffect(() => {
		clearTimeout(debounceRef.current);
		updateStep(true);
	}, [ i18n.language ]);

	const allRightHandler = useCallback(() => {
		switch (allRightMessageType) {
		case 'login':
			signIn();
			break;
		case 'get-progress':
			onPulse('update-score-button');
		}

		setAllRightMessageType('');
		setAllRightPopupIsOpen(false);
	}, [ allRightMessageType ]);

	return (
		<AllRightMessagePopup messageType={allRightMessageType} isOpen={allRightPopupIsOpen} handler={allRightHandler}/>
	);
});