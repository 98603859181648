import React, { useState, useEffect, useCallback } from 'react';
import { Button, Grid, Typography, Box } from '@mui/material';
import { IMAGES } from '../../constants/images';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useStores } from 'common-frontend/components/store-provider';
import { observer } from 'mobx-react';
import { PATHS } from '../../constants/paths';
import { GET_SCORE, SEND_PDF } from '../../services/api-calls';
import { REMOVE_RELATIONSHIP } from 'common-frontend/services/api-calls';
import { COURSES } from '../../constants/courses';
import { URLS } from 'common-frontend/constants/env';
import { AcceptPopup } from 'common-frontend/components/accept-popup';

export const StudiesPage = observer(() => {
	const { t } = useTranslation();
	const { AuthStore, UserStore, WalletStore, PopupStore } = useStores();
	const { authProfile } = AuthStore;
	const { user } = UserStore;
	const { getScore, scores, cleanWalletData } = WalletStore;
	const { openPopup, closePopup } = PopupStore;
	const authorized = authProfile && sessionStorage.getItem('access_token-education');
	const onboarded = authorized && user;

	const [ sendPdfLoading, setSendPdfLoading ] = useState(COURSES.reduce((acc, curr) => ((acc[curr] = false, acc)), {}));
	const [ finalPopupProps, setFinalPopupProps ] = useState({
		id: '', isOpen: false, title: '', confirmText: '', onClose: null, action: null });

	useEffect(() => {
		if (onboarded) {
			GET_SCORE('all');
		}
	}, [ onboarded ]);

	const sendPdf = useCallback((course) => {
		setSendPdfLoading({ ...sendPdfLoading, [course]: true });

		SEND_PDF(course).finally(() => {
			setSendPdfLoading({ ...sendPdfLoading, [course]: false });
		});
	}, []);

	useEffect(() => {
		const scoresArr = Object.values(scores);

		if (!sessionStorage.getItem('restart-popup-was-opened') && scoresArr.length === COURSES.length && scoresArr.every(score => score === 100)) {
			setFinalPopupProps({
				id: 'final-scenario-popup',
				isOpen: true,
				title: t('progress.final-scenario-popup.title'),
				confirmText: t('progress.final-scenario-popup.confirmText'),
				action: () => {
					openPopup();
					setFinalPopupProps({ ...finalPopupProps, isOpen: false });
					REMOVE_RELATIONSHIP().finally(() => {
						cleanWalletData();
						closePopup();
					});
				},
				onClose: () => {
					setFinalPopupProps({
						id: 'restart-scenario-popup',
						isOpen: true,
						title: t('progress.restart-scenario-popup.title'),
						confirmText: t('progress.restart-scenario-popup.confirmText'),
						action: () => {
							sessionStorage.setItem('restart-popup-was-opened', 'true');
							setFinalPopupProps({ ...finalPopupProps, isOpen: false });
							window.open(URLS.betaTest, '_blank');
						},
						onClose: () => {
							sessionStorage.setItem('restart-popup-was-opened', 'true');
							setFinalPopupProps({ ...finalPopupProps, isOpen: false });
						}
					});
				}
			});
		}
	}, [ scores ]);

	const getButton = (course) => {
		let buttonKey = 'button-';
		const disable = !(authProfile && user);

		if (onboarded) {
			if (!getScore(course) || getScore(course) === 0) {
				buttonKey += 'start';
			} else if (getScore(course) < 100) {
				buttonKey += 'continue';
			}
		} else {
			buttonKey += 'start';
		}

		return (authProfile && scores[course] === 100) ? (
			<Button
				id={ `certificate-button-${ course }` }
				className="course-button-link mt-16"
				disabled={sendPdfLoading[course]}
				variant="contained"
				size="large"
				onClick={() => { sendPdf(course); }}>
				{ t('progress.score-success.next-button') }
			</Button>
		) : (
			<Link id={`course-link-${course}`} className="course-link" to={ onboarded ? `${PATHS.user}/${course}/progress` : PATHS.user } disabled={disable}>
				<Button variant="contained" size="large" disabled={disable}>
					<Trans i18nKey={`studies.${buttonKey}`} values={{ course: t(`courses.${course}.name`) }} />
				</Button>
			</Link>
		);
	};

	return (
		<>
			<main className="main">
				<Grid container spacing={ 4 }>
					<Grid item lg={ 12 } md={ 12 }>
						<Typography variant="h1" className="mt-32 mb-32">{t('courses.nursing.article-1.title')}</Typography>
						<img className="studies-image" src={ IMAGES.girl } alt="girl" />
						<Box className="mt-32 mb-32">
							<Trans i18nKey="courses.nursing.article-1.text"><Typography></Typography><ol><li><Typography></Typography></li></ol></Trans>
						</Box>
						{/* <Typography variant="h2">{t('courses.nursing.article-2.title')}</Typography>
						<Typography className="mt-16 mb-32">{t('courses.nursing.article-2.text')}</Typography> */}
						{getButton('nursing')}
					</Grid>
					<Grid item lg={ 12 } md={ 12 }>
						<Typography variant="h1" className="mt-32 mb-32">{t('courses.math.article-1.title')}</Typography>
						<img className="studies-image" src={ IMAGES.keyboard } alt="keyboard" />
						<Typography className="mt-32 mb-32">
							<Trans i18nKey="courses.math.article-1.text" />
						</Typography>
						{/* <Typography variant="h2">{t('courses.math.article-2.title')}</Typography>
						<Typography className="mt-16 mb-32">{t('courses.math.article-2.text')}</Typography> */}
						{getButton('math')}
					</Grid>
				</Grid>
			</main>
			<MemoPopup finalPopupProps={ finalPopupProps } />
		</>
	);
});

const MemoPopup = React.memo(({ finalPopupProps }) => {
	return (
		<AcceptPopup id={ finalPopupProps.id } isOpen={ finalPopupProps.isOpen } action={ finalPopupProps.action }
			onClose={ finalPopupProps.onClose } title={ finalPopupProps.title } text={ finalPopupProps.confirmText } />
	);
});
