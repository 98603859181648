import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import './assets/scss/index.scss';
import './locales/i18n';
import 'common-frontend/utils/log';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);

