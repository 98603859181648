import React, { useEffect, useState } from 'react';
import { setSharedStores } from '../services/shared-stores';

const StoresContext = React.createContext(null);

export const StoresProvider = ({ stores, children }) => {
	const [ sharedStoresReady, setSharedStoresReady ] = useState(false);

	useEffect(() => {
		console.info('Shared stores set')
		setSharedStores(stores);
		setSharedStoresReady(true);
		
		return () => {
			console.info('Shared stores removed')
			setSharedStores(null);
			setSharedStoresReady(false);
		}
	}, []);

	return (
		<StoresContext.Provider value={ stores }>
			{sharedStoresReady && children}
		</StoresContext.Provider>
	);
};

export const useStores = () => React.useContext(StoresContext);
